.skill {
  padding: 0 0 50px 0;
  position: relative;
}

.skill-bx {
  border-radius: 5px;
  text-align: center;

  margin-bottom: 90px;
  margin-top: 95px;
}

.skill h2 {
  font-weight: 200 !important;
  text-align: center;
  opacity: 0.5;
}

.skill p {
  color: #b8b8b8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px 0 0px 0;
}
.skill-tec {
  font-size: 14px !important;
}

.skill-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}

.skill-slider .item img {
  width: 30%;
  margin: 0 auto 10px auto;
}

.background-image-left {
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}
.img-title-portofolio2 {
  width: 80px;
  height: 80px;
  margin-right: 10px;
}
.img-title-portofolio3 {
  width: 60px;
  height: 80px;
  margin-right: 10px;
}

@media screen and (max-width: 767px) {
  .skill p {
    width: 100%;
    font-size: 14px;
    padding: 0px;
    margin: 0px;
    margin-bottom: 20px;
  }
  .skill h2 {
    font-size: 30px;
    margin: 0px;
    padding-bottom: 10px;

    opacity: 0.8;
  }
  .skill-bx {
    padding: 0px;
    padding-bottom: 40px;
  }
  .img-title-portofolio2 {
    width: 60px;
    height: 50px;
  }
  .container-title-portfolio {
    display: flex;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    border-radius: 3px;
    overflow: hidden;
    justify-content: space-between;
  }
  .img-title-portofolio3 {
    width: 60px;
    height: 80px;
    margin-right: 10px;
  }
  .img-title-portofolio3 {
    width: 40px;
    height: 80px;
  }
}
