/*Retro Style Card*/
.project {
  padding: 100px 0px;
  position: relative;
}

.project h2 {
  font-size: 30px;
  font-weight: 200 !important;
  text-align: center;
  opacity: 0.5;
}

.project .p {
  color: #e4e3e3;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}

.project .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 3px;
  overflow: hidden;
}
.desc-demo {
  margin-top: 20px;
  padding-bottom: 20px;
  color: #b8b8b8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
}

.project .nav.nav-pills .nav-item {
  width: 33.33333%;
}
.container-title-portfolio {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72%;
  margin: 0 auto;
  border-radius: 3px;
  overflow: hidden;
  margin-bottom: 30px;
}
.container-portfolio {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72%;
  margin: 0 auto;
  border-radius: 3px;
  overflow: hidden;
}
.img-title-portofolio {
  width: 120px;
  height: 150px;
  margin-right: 10px;
}

.left-section {
  flex: 0 0 auto; /* Para que no se expanda */
}
.center-section {
  flex: 1; /* Para que ocupe el espacio restante */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.right-section {
  flex: 0 0 auto;
  /* width: 150px;  */
}

.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #d3cfcf;
  width: 100%;
  font-size: 22px;
  letter-spacing: 0.8px;
  font-weight: 200;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
  cursor: pointer;
}
.project .nav.nav-pills .nav-link:hover {
  color: #a00d6f;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Agrega un suave efecto de sombra */
}
.project .nav.nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.project .nav.nav-pills .nav-link.active::before {
  width: 100% !important;
}

.project .nav.nav-pills .nav-link.active {
  border-bottom: 1px solid rgba(255, 255, 255, 1);
  color: #ca32c3;
}

.nav-link#projects-tabs-tab-first {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 55px 0px 0px 55px;
}

.nav-link#projects-tabs-tab-second {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.nav-link#projects-tabs-tab-third {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 55px 55px 0;
}

.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
}

.proj-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, #aa367c -5.91%, #4a2fbd 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}

.proj-imgbx:hover::before {
  height: 100%;
}

.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}

.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}

.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}

.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}

.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}

.card {
  margin: 15px;
}

.gallery {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 72%;
  margin: 0 auto;
  border-radius: 3px;
  overflow: hidden;
  gap: 15px;
}

.row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery .video {
  transition: all 350 ms ease;
  cursor: pointer;
  margin-bottom: 12px;
  min-width: 253px;
  max-width: 309px;
  min-height: 258px;
  /* max-height: 291px; */
}
.video-box {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}
.rh5v-DefaultPlayer_component {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}

.galley .video:hover {
  transform: scale(0.98);
}

@media (max-width: 991px) {
  .gallery {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
}

@media (max-width: 480px) {
  .gallery {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-width: 100%;
    -moz-column-width: 100%;
    column-width: 100%;
  }
}

.video-container {
  border-radius: 10px;
  overflow: hidden;
}

.title-video {
  color: #fff;
  font-size: 15px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 56%;
  opacity: 0.8;
}

.model {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  overflow: hidden;
  z-index: 999;
}

.model .model-close-btn {
  position: fixed;
  top: 50px;
  right: 10px;
  padding: 5px;
  background-color: rgb(247, 247, 247);
  color: rgb(0, 0, 0);
  font-size: 2rem;
  cursor: pointer;
}

.model .video-container {
  width: auto;
  max-width: 75%;
  height: auto;
  max-height: 100%;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 1.5rem 0 1.5rem;
  margin: 2rem auto;
}

.texwebdemo {
  margin-top: 60px;
}
.section-container {
  margin-top: 120px;
}

@media screen and (max-width: 767px) {
  .project h2 {
    font-size: 24px;
    color: #e4e3e3; /* Tamaño de fuente reducido para dispositivos más pequeños */
  }

  .project .nav.nav-pills .nav-link {
    font-size: 16px;
    padding: 10px 0; /* Ajuste del relleno para dispositivos más pequeños */
  }

  .project .nav.nav-pills .nav-link::before {
    height: 80%; /* Ajuste del tamaño del fondo para dispositivos más pequeños */
  }

  .desc-demo {
    font-size: 14px;
    width: 100%;
    padding: 10px;
    /* Tamaño de fuente reducido para dispositivos más pequeños */
  }
  .project {
    padding: 10px 0px;
  }
  .skill {
    margin-top: 20px;
  }
  .project .nav.nav-pills .nav-link:hover {
    color: #fff; /* Cambia el color del texto al pasar el ratón */
    /* Cambia el gradiente de colores al pasar el ratón */
  }
  .project .nav.nav-pills .nav-link {
    font-size: 14px;
    padding: 10px 0; /* Ajuste del relleno para dispositivos más pequeños */
  }

  .project .nav.nav-pills {
    margin: 0 auto;
    border-radius: 3px;
    overflow: hidden;
    width: 80%;
    background-color: rgba(90, 87, 87, 0.2);
  }
  .project .nav.nav-pills .nav-link.active {
    border-bottom: 0px solid rgba(255, 255, 255, 1);
  }
  .desc-demo {
    color: #b8b8b8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 0px auto 30px auto;
    text-align: center;
  }
  .img-title-portofolio {
    width: 60px;
    height: 67px;
  }
  .right-section {
    width: 60px;
  }
  .container-title-portfolio {
    display: flex;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    border-radius: 3px;
    overflow: hidden;
    margin-bottom: 30px;
    justify-content: space-between;
  }
  .gallery {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0px;
  }
  .gallery .video {
    min-height: 218px;
  }
}
