.animation-container {
  width: 100%;
}
@media screen and (max-width: 748px) {
  .animation-container {
    width: 30%;
    margin-left: 254px;
    margin-top: -60px;
  }
}
