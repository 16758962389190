.card {
  width: 190px;
  height: 390px;
  --border-radius: 2px;
  --border-width: 1px;
  appearance: none;
  position: relative;
  padding: 1.5em;
  border: 0;
  background: rgb(0, 0, 0);
  font-size: 14px;
  font-weight: 200 !important;
  color: #fff;
  z-index: 2;
  border-radius: 2px;
  transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.card::after {
  --m-i: linear-gradient(#000, #000);
  --m-o: content-box, padding-box;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: var(--border-width);
  border-radius: var(--border-radius);
  background-image: conic-gradient(
    #488cfb,
    #29dbbc,
    #ddf505,
    #ff9f0e,
    #e440bb,
    #655adc,
    #488cfb
  );
  -webkit-mask-image: var(--m-i), var(--m-i);
  mask-image: var(--m-i), var(--m-i);
  -webkit-mask-origin: var(--m-o);
  mask-origin: var(--m-o);
  -webkit-mask-clip: var(--m-o);
  mask-composite: exclude;
  -webkit-mask-composite: destination-out;
  filter: hue-rotate(0);
  animation: rotate-hue linear 500ms infinite;
  animation-play-state: paused;
}

.modal-content {
  background-color: #96266e;
  border-radius: 3px;
}
.detail-modal {
  font-weight: 100 !important;
  font-size: 1rem;
  opacity: 0.9;
}
.description-modal {
  font-weight: 200 !important;

  opacity: 0.5;
}

.card:hover::after {
  animation-play-state: running;
}

@keyframes rotate-hue {
  to {
    filter: hue-rotate(1turn);
  }
}

.card,
.card::after {
  box-sizing: border-box;
}

.card:active {
  --border-width: 5px;
}

.card:hover {
  transform: translateY(-10px);
}

.card:hover .card__image img {
  transform: scale(1.2);
}

.card:hover .card__content {
  transform: translateY(-100%);
}

.text-body {
  color: rgb(189, 182, 182) !important;
  font-weight: 200 !important;
}
.descrition-container {
  min-height: 110px;
  max-height: 50px;
}

/*Text*/
.text-title-project {
  font-size: 1em;
  background: white;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 200 !important;
}

.imgcard {
  width: 50%;
  border-radius: 1px;
  min-width: 250px;
  max-height: 110px;
  object-fit: cover;
}

.cssbuttons-io {
  position: relative;
  width: 40%;
  font-family: inherit;
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 0.05em;
  color: white;
  border: 1px solid rgb(247, 247, 247);
  cursor: pointer;
  overflow: hidden;
  margin: 5px;
}

.cssbuttons-io svg {
  width: 1.2em;
  height: 1.2em;
  margin-right: 0.5em;
}

.cssbuttons-io span {
  position: relative;
  z-index: 10;
  transition: color 0.4s;
  display: inline-flex;
  align-items: center;
  padding: 0.8em 1.2em 0.8em 1.05em;
}

.cssbuttons-io::before,
.cssbuttons-io::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.cssbuttons-io::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(252, 70, 100);
  transform: translateX(-100%);
  transition: all 0.3s;
  z-index: -1;
}

.cssbuttons-io:hover::before {
  transform: translateX(0);
}

.cssbuttons-io:active {
  transform: scale(0.95);
}

.button2 {
  --color: #e2e2e2;
  padding: 1em 2em 1em;
  background-color: transparent;
  border-radius: 0.3em;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: 0.5s;
  font-weight: 300;
  font-size: 10px;
  border: 1px solid;
  font-family: inherit;
  text-transform: uppercase;
  color: var(--color);
  z-index: 1;
  margin-bottom: 5px;
  min-width: 100px;
}

.button2::before,
.button2::after {
  content: "";
  display: block;
  width: 0px;
  height: 50px;
  transform: translate(-50%, -50%);
  position: absolute;
  border-radius: 100%;
  z-index: -1;
  background-color: var(--color);
  transition: 1s ease;
}

.button2::before {
  top: -1em;
  left: -1em;
}

.button2::after {
  left: calc(100% + 1em);
  top: calc(100% + 1em);
}

.button:hover::before,
.button2:hover::after {
  height: 410px;
  width: 410px;
}

.button2:hover {
  color: rgb(10, 25, 30);
}

.button2:active {
  filter: brightness(0.8);
}

.button4 {
  --color: #e2e2e2;
  background-color: transparent;
  border-radius: 3px;
  padding-left: 2.5em;
  padding-right: 2.5em;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: 0.5s;
  font-size: 12px;
  border: 1px solid;
  font-family: inherit;
  color: var(--color);
  z-index: 1;
}
.button4:hover {
  color: rgb(161, 76, 179);
  opacity: 0.8;
}

.button3::before,
.button3::after {
  content: "";
  display: block;
  width: 50px;
  height: 50px;
  transform: translate(-50%, -50%);
  position: absolute;
  border-radius: 50%;
  z-index: -1;
  background-color: var(--color);
  transition: 1s ease;
}

.button3::before {
  top: -1em;
  left: -1em;
}

.button3::after {
  left: calc(100% + 1em);
  top: calc(100% + 1em);
}

.button:hover::before,
.button3:hover::after {
  height: 410px;
  width: 410px;
}

.button3:hover {
  color: rgb(10, 25, 30);
}

.button3:active {
  filter: brightness(0.8);
}

.container-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  min-height: 50px;
}

.text-title-porject {
  color: rgb(238, 229, 229);
  margin: 0px !important;
  font-size: 16px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  font-weight: 100 !important;
}
.container-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.name-tec {
  color: rgb(129, 128, 128);
  margin-bottom: 10px;
}
